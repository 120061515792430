.video {
  border-radius: 10px;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 300px;
  position: relative;
}

.video.youtube > img.thumbnail {
  border-radius: 10px;
}

.video.youtube .youtube.play {
  transition: background-image 0.25s ease;
  background-image: url(../assets/youtube_social_icon_dark.png);
  height: 45px;
  width: 64px;
  opacity: 0.8;
  position: absolute;
}

.video.youtube:hover .youtube.play {
  background-image: url(../assets/youtube_social_icon_red.png);
  opacity: 1;
}
