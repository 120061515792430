.hiddenText {
  visibility: hidden;
  margin: 0;
}

.Icon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 32px;
  width: 142px;
  margin: 6px;
}

.Icon.youtube {
  background-image: url(../assets/yt_logo_mono_dark.png);
  position: absolute;
  bottom: -5px;
  right: -20px;
  background-size: 65%;
  opacity: 0.8;
}

.spotlight.youtube:hover .Icon.Icon.youtube {
  opacity: 1;
}

.Icon.spotify {
  background-image: url(../assets/Spotify_Logo_RGB_White.png);
}

.Icon.apple-music {
  background-image: url(../assets/US-UK_Apple_Music_Lockup_all-white.svg);
}

.Icon.itunes {
  background-image: url(../assets/US_UK_iTunes_Store_Get_Solid_Lockup_RGB_wht_012618.svg);
}

.Icon.soundcloud {
  background-image: url(../assets/sc_sbs_white_header@2x.png);
  width: 180px;
}

.Icon.pandora {
  background-image: url(../assets/pandora_wordmark.svg);
  width: 118px;
}
