.song-form {
  padding: 5px 20px 5px 10px;
  flex: 1;
  /* position: relative; */
}

.stepper {
  position: sticky;
  bottom: 0;
  z-index: 1;
}

.MuiInputBase-input.MuiOutlinedInput-input {
  background-color: rgba(255,255,255,0.04);
}

.disabled-link {
  pointer-events: none;
}

.disabled-link, .active-link {
  text-decoration: none;
}

/* .fab {
  position: absolute;
  bottom: 16px;
  right: 16px;
} */