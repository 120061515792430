.App {
  text-align: center;
}

.main {
  transition: margin-left 0.25s ease-in-out;
  text-align: center;
}

/* .main.open {
  margin-left: 100vw;
} */

header {
  display: flex;
  max-width: 300px;
  max-width: 85%;
}

canvas {
  width: 100vw;
  height: 100vh;
  position: fixed;
  opacity: 0;
  z-index:-1;
  left: 50%;
  transform: translateX(-50%);
  object-fit: cover;
}

canvas.fade-in {
  transition: opacity 0.5s;
  opacity: 0.75;
}

.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column wrap;
}

.container > * {
  margin: 15px 0 0;
}

h1 {
  margin: 0;
  line-height: 1;
}

h3 {
  margin: 5px 0 0;
  line-height: 1;
}

h4, h5 {
  margin: 15px 0;
}

img {
  width: 100%;
  height: auto;
}

.uppercase {
  text-transform: uppercase;
}

/* img.artwork.small {
  height: 58px;
  width: auto;
  margin: 0 10px 0 0;
  border-radius: 10%;
}  */

.artwork, .video, .social {
  transition: transform 0.2s cubic-bezier(0.39, 0.575, 0.565, 1);
}

.artwork:hover,
.video:hover, .social:hover {
  transform: scale(1.05);
}

.light {
  color: #eee;
}

/*
.dark {
  color: #2b2b2b;
}*/

.social {
  margin: 0 5px;
  display: inline-block;
}

/* .social.facebook {
  color: #5890ff;
}
.social.twitter {
  color: #1da1f2;
}
.social.instagram .fa-instagram {
  color: transparent;
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  background: -webkit-radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  border-radius: 30%;
  height: 32px;
  width: 32px;
  text-align: center;
  background-clip: border-box;
}
.social.youtube {
  color: #ff0000;
} */

.drawer-header {
    display: flex;
    align-items: center;
    padding: 5px 20px;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #202124;
}

button.overflow-menu {
  position: absolute;
  cursor: pointer;
  top:5px;
  right: 5px;
  z-index: 1;
}

.editor-menu {
  height: 100%; 
  position: fixed; 
  z-index: 2; 
  top: 0; 
  width: 100vw;
  left: -100vw;
  overflow-x: hidden; 
  visibility: hidden;
  transition: left 0.25s ease-in-out, visibility 0s 0.25s;
  -webkit-transition: left 0.25s ease-in-out, visibility 0s 0.25s;
  background-color: #303030;
}

.editor-menu.open {
  left: 0px;
  visibility: visible;
  transition: left 0.25s ease-in-out;
  -webkit-transition: left 0.25s ease-in-out;
  display: flex;
  flex-direction: column;

}

.editor-button.menu {
  top: 5px;
  left: 5px;
  z-index: 1;
  position: absolute;
  cursor: pointer;
}

.editor-button.menu.open {
  transition: visibility 0s 0.25s;
  -webkit-transition: visibility 0s 0.25s;
  visibility: hidden;
}

svg:not(:root).svg-inline--fa {
  height: 1em;
  width: 1em;
}

@media only screen and (min-width: 680px) {
  .editor-menu,
  .editor-menu-header {
    width: 50vw;
  }

  .editor-menu {
    left: -50vw;
  }

  .main.open {
    margin-left: 50vw;
  }
}

@media only screen and (min-width: 1024px) {
  .editor-menu,
  .editor-menu-header {
    width: 512px;
  }

  .editor-menu {
    left: -512px;
  }

  .main.open {
    margin-left: 512px;
  }
}
