.pretty-button {
  margin: 10px auto;
  border-radius: 10px;
  display: flex;
  flex: 1;
  /* align-items: center; */
  justify-content: center;
  transition: background-color 0.25s ease, transform 0.2s cubic-bezier(0.39, 0.575, 0.565, 1);
  max-width: 300px;
  position: relative;
}

.pretty-button:hover {
  transform: scale(1.05);
}

.pretty-button::after {
  content: '';
  box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
  opacity: 0;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  transition: opacity 0.3s ease-in-out;
}

.pretty-button:hover::after {
  opacity: 1;
}


.pretty-button.spotify {
  background-color: #1ed761;
}

.pretty-button.spotify:hover {
  background-color: #1db954;
}

.pretty-button.apple-music {
  background-color: #da0f47;
}

.pretty-button.apple-music:hover {
  background-color: #bd073a;
}

.pretty-button.itunes {
  background-color: #333333;
}

.pretty-button.itunes:hover {
  background-color: #252525;
}

.pretty-button.soundcloud {
  background-color: #f50;
}

.pretty-button.soundcloud:hover {
  background-color: #d85a1b;
}

.pretty-button.pandora {
  background-color: #00a0ee;
}

.pretty-button.pandora:hover {
  background-color: rgb(0, 138, 207);
}
